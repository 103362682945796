import { BackgroundImage, MantineTheme, Sx } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { ReactNode } from "react"
import { MOBILE_BREAKPOINT_PX } from "../../CONSTS";
import { BreakpointVariant, Gradient } from "../../TYPES";
import PageSection from "./PageSection";

type Props = {
  children: ReactNode;

  backgroundImage?: string;
  backgroundPosition?: BreakpointVariant<string>;
  clipPath?: BreakpointVariant<string>;

  backgroundOverlay?: BreakpointVariant<Gradient>;
}

export default function HeroSection(props: Props) {

  // Hooks
  const { width } = useViewportSize();
  const isMobile = width <= MOBILE_BREAKPOINT_PX;

  // Styles
  const bgStyle: Sx = (theme: MantineTheme) => ({
    maxWidth: '100vw',
    height: '100vh',
    position: 'absolute',
    zIndex: -2,

    clipPath: !props.clipPath ? null : isMobile && props.clipPath.mobile ?
      props.clipPath.mobile : props.clipPath.desktop,
    backgroundPosition: !props.backgroundPosition ? null : isMobile && props.backgroundPosition.mobile ?
      props.backgroundPosition.mobile : props.backgroundPosition.desktop || 'center',
  });
  const overlayStyle: Sx = (theme: MantineTheme) => ({
    maxWidth: '100vw',
    height: '100vh',
    position: 'absolute',
    zIndex: -1,

    backgroundImage: !props.backgroundOverlay ? null :
      isMobile && props.backgroundOverlay.mobile ?
        `linear-gradient(${props.backgroundOverlay.mobile.deg || 45
        }deg, ${props.backgroundOverlay.mobile.from
        }, ${props.backgroundOverlay.mobile.to
        })` :
        `linear-gradient(${props.backgroundOverlay.desktop.deg || 45
        }deg, ${props.backgroundOverlay.desktop.from
        }, ${props.backgroundOverlay.desktop.to
        })`,
  });

  return (
    <>
      <BackgroundImage
        src={props.backgroundImage}
        sx={bgStyle}
      />
      <BackgroundImage
        src={null}
        sx={overlayStyle}
      />
      <PageSection
        grow
      >
        {props.children}
      </PageSection>
    </>
  )
}