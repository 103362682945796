import { Box, MantineTheme, Sx } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { CSSProperties, ReactNode } from "react"
import { MOBILE_BREAKPOINT_PX } from "../../CONSTS";
import { BreakpointVariant, FlexAlign, FlexDirection, FlexJustify } from "../../TYPES";

type ColumnProps = {
  children: ReactNode;

  direction?: BreakpointVariant<FlexDirection>;
  justify?: BreakpointVariant<FlexJustify | string>;
  align?: BreakpointVariant<FlexAlign | string>;
  gap?: number;

  grow?: number;

  width?: BreakpointVariant<number | string>;
  height?: BreakpointVariant<number | string>;

  style?: CSSProperties;
}

const Column = function Column(props: ColumnProps) {

  // Hooks
  const { width } = useViewportSize();
  const isMobile = width <= MOBILE_BREAKPOINT_PX;

  // Styles
  const columnStyle: Sx = (theme: MantineTheme) => ({
    width: props.width ? isMobile && props.width.mobile ?
      props.width.mobile : props.width.desktop : null,
    height: props.height ? isMobile && props.height.mobile ?
      props.height.mobile : props.height.desktop : '100%',

    flexGrow: props.grow,

    display: 'flex',
    flexDirection: props.direction ?  isMobile && props.direction.mobile ?
      props.direction.mobile : props.direction.desktop : 'column',
    alignItems: props.align ? isMobile && props.align.mobile ?
      props.align.mobile : props.align.desktop : null,
    justifyContent: props.justify ? isMobile && props.justify.mobile ?
      props.justify.mobile : props.justify.desktop : FlexJustify.Center,
    gap: props.gap || theme.spacing.sm,
  });

  return (
    <Box
      sx={columnStyle}
      style={props.style}
    >
      {props.children}
    </Box>
  )
}

type ContainerProps = {
  children: ReactNode;

  grow?: boolean;
  width?: BreakpointVariant<number | string>;
  height?: BreakpointVariant<number | string>;

  direction?: BreakpointVariant<FlexDirection>;
  justify?: BreakpointVariant<FlexJustify | string>;
  align?: BreakpointVariant<FlexAlign | string>;
  gap?: number;

  style?: CSSProperties;
}

Column.Container = function ColumnContainer(props: ContainerProps) {

  // Hooks
  const { width } = useViewportSize();
  const isMobile = width < MOBILE_BREAKPOINT_PX;

  // Styles
  const containerStyle: Sx = (theme: MantineTheme) => ({
    width: props.width ? isMobile && props.width.mobile ?
      props.width.mobile : props.width.desktop : null,
    height: props.height ? isMobile && props.height.mobile ?
      props.height.mobile : props.height.desktop : '100%',

    flexGrow: props.grow ? 1 : 0,

    display: 'flex',
    flexDirection: props.direction ? isMobile && props.direction.mobile ?
      props.direction.mobile : props.direction.desktop : 'row',
    alignItems: props.align ? isMobile && props.align.mobile ?
      props.align.mobile : props.align.desktop : null,
      justifyContent: props.justify ? isMobile && props.justify.mobile ?
      props.justify.mobile : props.justify.desktop : FlexJustify.Center,
    gap: props.gap || theme.spacing.sm,
  });

  return (
    <Box
      sx={containerStyle}
      style={props.style}
    >
      {props.children}
    </Box>
  )
}

export default Column;