import { Box, Container, MantineTheme, Sx } from "@mantine/core";
import { CSSProperties, ReactNode } from "react"

type Props = {
  children: ReactNode;

  height?: any;
  grow?: boolean;

  gap?: number;

  paddingHorizontal?: string | number;
  paddingVertical?: string | number;

  backgroundColor?: string;
  backgroundOpacity?: string;

  style?: CSSProperties;
  backgroundStyle?: CSSProperties;
}

export default function PageSection(props: Props) {
  const backgroundStyle: Sx = (theme: MantineTheme) => ({
    backgroundColor: props.backgroundColor ? `${
      theme.colors[props.backgroundColor][4]
    }${
      props.backgroundOpacity ? props.backgroundOpacity : 'FF'
    }` : null,

    padding: `${props.paddingVertical || 0}px ${props.paddingHorizontal || 0}px`
  });
  const containerStyle: Sx = (theme: MantineTheme) => ({
    height: props.grow ? '100vh' : props.height || 'auto',
    padding: theme.spacing.md,
    gap: props.gap || theme.spacing.md,
    boxSizing: 'border-box',
    flex: 1,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: props.grow ? 'center' : '',

    position: 'relative',
  });

  return (
    // Background
    <Box
      sx={backgroundStyle}
      style={props.backgroundStyle}
    >
      {/* Container */}
      <Container
        size='md'
        px='sm'
        sx={containerStyle}
        style={props.style}
      >
        {props.children}
      </Container>
    </Box>
  )
}