import { MantineTheme, Sx, Text as BaseText } from "@mantine/core";
import { CSSProperties, ReactNode } from "react";
import reactStringReplace from "react-string-replace";
import { ComponentSize, TextTransform } from "../TYPES";

type Props = {
  children: ReactNode;
  weight?: number | string;
  align?: 'left' | 'right' | 'center' | 'justify';
  transform?: TextTransform;

  color?: string;
  size?: number | string | ComponentSize;
  lineClamp?: number;

  isMonospace?: boolean;
  style?: CSSProperties;

  opacity?: number;
  gradient?: {
    to: string,
    from: string,
    deg?: number,
  };
}

export default function Text(props: Props) {

  let text: any = props.children;

  // Style
  const textStyle: Sx = (theme: MantineTheme) => ({
    fontFamily: props.isMonospace ? theme.fontFamilyMonospace : null,

    background: !props.gradient ? null :
      `-webkit-linear-gradient(${props.gradient.deg || 45}deg, ${theme.colors[props.gradient.from][4]
      }, ${theme.colors[props.gradient.to][4] })`,
    WebkitBackgroundClip: !props.gradient ? null : 'text',
    WebkitTextFillColor: !props.gradient ? null : 'transparent',
    opacity: props.opacity,
  });

  /** Run through formatters */
  text = reactStringReplace(text, '<br>', (match, i) => (
    <span key={match + i}>
      <br /><br />
    </span>
  ));
  text = reactStringReplace(text, /<b>(.*?)<\/b>/, (match, i) => (
    <BaseText
      weight={800}
      key={match + i}
      component='span'
      transform={props.transform}
    >
      {match}
    </BaseText>
  ))

  return (
    <BaseText
      weight={props.weight}
      align={props.align}
      size={props.size}
      lineClamp={props.lineClamp}
      transform={props.transform}
      color={props.color}

      sx={textStyle}
      style={props.style}
    >
      {text}
    </BaseText>
  )
}